"use client";

import { useEffect, useState } from "react";

export const DNBLogo = () => (
    <svg width="74" height="50" viewBox="0 0 74 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M70.7276 24.9783C70.2976 24.6343 69.8247 24.2904 69.2658 24.0324L69.1369 23.9464L69.2658 23.8605C70.2547 23.0006 71.4155 21.3239 71.4155 18.5294C71.4155 15.9069 70.5126 13.9722 68.7499 12.7684C67.2452 11.6936 65.1816 11.1777 62.4301 11.1777H54.5195C54.0466 11.1777 53.7026 11.5647 53.7026 11.9946L53.7456 37.9619C53.7456 38.4348 54.1326 38.7788 54.5625 38.7788H63.5909C66.4283 38.7788 68.7499 38.1769 70.3836 36.9731C71.3295 36.2852 72.1033 35.3824 72.5762 34.3076C73.0921 33.2328 73.3501 31.943 73.3501 30.5243C73.3501 28.2887 72.4043 26.354 70.7276 24.9783ZM56.2822 13.7573H62.4731C64.6657 13.7573 66.2564 14.1012 67.3312 14.8321C67.9761 15.305 68.8789 16.2078 68.9219 18.5294C68.9219 22.6567 65.6545 23.0006 64.6657 23.0006H56.3252L56.2822 13.7573ZM63.6338 36.1992H56.3252V25.5372H64.6657C68.277 25.5372 70.8136 27.5578 70.8136 30.4813C70.7706 31.9 70.7706 36.1992 63.6338 36.1992Z"
            fill="#007272"
        />
        <path
            d="M18.234 15.348C17.0732 13.9292 15.5685 12.8544 13.8488 12.1235C12.344 11.5216 10.7103 11.2207 8.99065 11.2207H1.467C0.994085 11.2207 0.650146 11.6076 0.650146 12.0805L0.693139 38.0479C0.693139 38.5208 1.08007 38.8647 1.50999 38.8647H9.03364C10.7533 38.8647 12.387 38.5638 13.8918 37.9619C15.6115 37.274 17.0732 36.1992 18.234 34.7375C20.8995 31.4701 21.2864 27.3428 21.2864 25.1932C21.2864 23.3445 20.9855 18.7014 18.234 15.348ZM16.2563 33.1038C14.1067 35.7263 11.1403 36.2852 9.03364 36.2852H3.27267L3.22968 13.8432H8.99065C11.0973 13.8432 14.0637 14.3591 16.2133 16.9817C18.5349 19.7762 18.7069 23.9894 18.7069 25.1932C18.7069 27.0419 18.406 30.4812 16.2563 33.1038Z"
            fill="#007272"
        />
        <path
            d="M47.469 0L47.555 38.1341C47.555 38.4781 47.34 38.779 47.0391 38.865C46.9531 38.908 46.8671 38.908 46.7811 38.908C46.5232 38.908 46.3082 38.779 46.1363 38.5641L29.0683 16.9819L29.1113 50H26.5318L26.4458 11.7799C26.4458 11.4359 26.6608 11.135 27.0047 11.049C27.0907 11.006 27.1767 11.006 27.2627 11.006C27.5206 11.006 27.7356 11.092 27.9075 11.307L44.9325 32.9321L44.8895 0H47.469Z"
            fill="#007272"
        />
    </svg>
);

export const SB1LightLogo = () => (
    <svg width="194" height="54" viewBox="0 0 194 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1647 38.0156C15.6363 38.0156 12.8405 37.207 12.3245 37.045C12.0528 36.937 11.9173 36.7217 11.9173 36.479V36.3443L12.2702 34.5637C12.3245 34.2944 12.5144 34.2137 12.7318 34.2137C12.7861 34.2137 12.8405 34.2137 12.9217 34.2404C13.492 34.3484 16.2602 35.1304 18.8662 35.1304C21.0917 35.1304 23.073 34.483 23.073 32.1911C23.073 29.8712 21.038 29.6292 18.4046 29.2512C15.4732 28.8192 11.7006 28.0372 11.7006 23.452C11.7006 18.7328 15.229 17.4922 19.409 17.4922C22.5852 17.4922 25.218 18.2475 25.8144 18.4088C26.1405 18.4902 26.3035 18.7062 26.3035 18.9755V19.1101L25.9238 20.8634C25.8963 21.0521 25.7064 21.2407 25.489 21.2407C25.4622 21.2407 25.4347 21.2141 25.4078 21.2141C24.1049 20.9441 22.0961 20.4048 19.7075 20.4048C17.6987 20.4048 15.5819 20.7828 15.5819 23.048C15.5819 25.2866 17.8618 25.61 20.2778 25.9606C23.4534 26.4459 27.063 26.9046 27.063 31.9211C27.063 36.6403 23.2092 38.0156 19.1647 38.0156Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.5396 30.8421L49.1735 31.3814C48.0604 31.5428 47.0292 31.7048 47.0292 33.4574C47.0292 35.5613 48.7394 35.7233 50.0423 35.7233C51.1004 35.7233 51.9693 35.5347 52.5396 35.3453V30.8421ZM53.652 37.6919C52.7019 37.9079 51.399 38.0692 49.8793 38.0692C46.948 38.0692 43.3376 37.7733 43.3376 33.4847C43.3376 30.0055 45.5363 29.6288 47.7082 29.2775L52.5396 28.4948V27.6322C52.5396 26.8769 52.4577 26.3376 51.7794 26.0409C51.3446 25.8522 50.6931 25.7716 49.7163 25.7716C47.8705 25.7716 45.8617 26.3109 45.0479 26.5262C44.966 26.5262 44.9123 26.5536 44.858 26.5536C44.7218 26.5536 44.5863 26.4996 44.5587 26.3109L44.179 24.639V24.531C44.179 24.2883 44.3696 24.1803 44.5051 24.1263C45.1566 23.8836 47.6531 23.209 50.4221 23.209C52.349 23.209 53.652 23.479 54.5209 23.9643C55.905 24.7196 56.1767 25.9869 56.1767 27.4435V34.8873C56.1767 36.9099 55.4977 37.2606 53.652 37.6919Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.8603 25.9869C64.2051 25.9869 62.7935 26.1489 62.0333 26.3376V37.3146C62.0333 37.5573 61.8702 37.7193 61.6267 37.7193H58.8577C58.6947 37.7193 58.3961 37.5573 58.3961 37.3146V25.6636C58.3961 24.6116 59.0201 24.2883 60.323 23.9103C61.5173 23.533 63.4449 23.2637 64.9921 23.2363H65.453C65.8335 23.2363 65.8335 23.2363 66.0233 23.317C66.132 23.371 66.2676 23.479 66.2676 23.7483V25.5016C66.2676 25.7716 66.2407 25.9869 65.8603 25.9869Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.2399 27.6908C75.887 26.2075 75.0732 25.7215 73.4716 25.7215C71.6527 25.7215 70.8926 26.2608 70.594 27.6094C70.4041 28.3648 70.4041 29.1474 70.3773 30.0367L76.4573 30.0094C76.4573 29.1474 76.4298 28.4188 76.2399 27.6908ZM80.067 31.7093C80.067 32.0053 79.9039 32.3293 79.4973 32.3293H70.4041C70.4041 33.57 70.757 34.2446 71.4897 34.8106C72.1955 35.3766 73.2267 35.6192 74.6927 35.6192C76.3761 35.6192 77.6509 35.2152 78.4929 34.9719C78.5473 34.9452 78.5741 34.9452 78.6285 34.9452C78.7915 34.9452 78.927 35.0532 79.0089 35.3232L79.3611 36.6979C79.3611 36.7252 79.3886 36.7799 79.3886 36.8332C79.3886 37.0492 79.2531 37.1565 79.0626 37.2379C78.14 37.6152 76.2943 38.1005 74.15 38.1005C68.8301 38.1005 66.7401 36.0239 66.7401 30.7387C66.7401 25.6682 68.1511 23.2402 73.4441 23.2402C76.1856 23.2402 77.8683 23.9416 78.8459 25.2362C79.7684 26.4502 80.067 28.1761 80.067 30.414V31.7093Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.832 30.8421L106.467 31.3814C105.354 31.5428 104.322 31.7048 104.322 33.4574C104.322 35.5613 106.032 35.7233 107.335 35.7233C108.393 35.7233 109.262 35.5347 109.832 35.3453V30.8421ZM110.945 37.6919C109.995 37.9079 108.692 38.0692 107.172 38.0692C104.24 38.0692 100.63 37.7733 100.63 33.4847C100.63 30.0055 102.83 29.6288 105.001 29.2775L109.832 28.4948V27.6322C109.832 26.8769 109.75 26.3376 109.072 26.0409C108.638 25.8522 107.986 25.7716 107.01 25.7716C105.163 25.7716 103.154 26.3109 102.34 26.5262C102.259 26.5262 102.204 26.5536 102.151 26.5536C102.015 26.5536 101.88 26.4996 101.852 26.3109L101.472 24.639V24.531C101.472 24.2883 101.661 24.1803 101.798 24.1263C102.449 23.8836 104.946 23.209 107.715 23.209C109.642 23.209 110.945 23.479 111.813 23.9643C113.198 24.7196 113.469 25.9869 113.469 27.4435V34.8873C113.469 36.9099 112.79 37.2606 110.945 37.6919Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.985 37.7193H125.189C124.944 37.7193 124.755 37.5573 124.755 37.3146V27.7942C124.755 26.2296 124.158 25.6903 122.719 25.6903C121.09 25.6903 119.326 26.1489 119.326 26.1489V37.3146C119.326 37.5573 119.163 37.7193 118.919 37.7193H116.15C115.879 37.7193 115.689 37.5573 115.689 37.3146V25.6903C115.689 24.6656 116.205 24.315 117.725 23.9103C119.109 23.533 121.389 23.209 123.126 23.209C126.519 23.209 128.392 24.0723 128.392 27.3082V37.3146C128.392 37.5573 128.229 37.7193 127.985 37.7193Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143.724 37.4756C143.724 37.5836 143.616 37.7189 143.453 37.7189H139.816C139.68 37.7189 139.544 37.7189 139.408 37.5569L135.201 31.8658H134.279V37.3143C134.279 37.5569 134.143 37.7189 133.872 37.7189H131.076C130.832 37.7189 130.641 37.5569 130.641 37.3143V18.4621C130.641 18.1388 130.777 17.9768 131.103 17.8962L133.763 17.4375H133.872C134.116 17.4375 134.279 17.5995 134.279 17.8962V29.4385H135.201L139.491 23.748C139.599 23.5867 139.708 23.56 139.843 23.56L143.155 23.6407C143.345 23.6407 143.426 23.7753 143.426 23.8833C143.426 23.9373 143.399 23.964 143.372 24.018L138.242 30.4371L143.671 37.3409C143.698 37.3683 143.724 37.4223 143.724 37.4756Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.244 35.588C33.7787 35.588 32.6656 35.2653 32.6656 35.2653V26.1762C32.6656 26.1762 34.2671 25.7449 35.5151 25.7449C37.5782 25.7449 38.4739 26.2029 38.4739 30.5721C38.4739 34.6713 37.7137 35.588 35.244 35.588ZM36.2752 23.209C34.457 23.209 32.367 23.5063 30.7387 24.0723C29.5169 24.4496 29.0284 24.8543 29.0284 26.2569V42.9511C29.0284 43.2204 29.241 43.4412 29.6325 43.3708C30.0232 43.3011 32.204 42.9005 32.204 42.9005C32.5301 42.8465 32.6656 42.6845 32.6656 42.3612V37.6591C33.6287 37.8922 34.8436 38.0692 36.1941 38.0692C41.2429 38.0692 42.1922 34.914 42.1922 30.6528C42.1922 26.3649 41.5139 23.209 36.2752 23.209Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.5075 28.9531L86.405 28.7918V34.9403H91.5075C94.2489 34.9403 95.1446 33.7543 95.1446 32.1083C95.1446 29.8978 94.412 29.0338 91.5075 28.9531ZM91.643 20.566H86.405V26.3372L91.4538 26.1219C93.5163 26.0405 94.575 25.3125 94.575 23.2633C94.575 21.3746 93.6518 20.566 91.643 20.566ZM91.9966 37.7189H82.9577C82.7128 37.7189 82.4961 37.5295 82.4961 37.2876V18.2194C82.4961 17.9767 82.7128 17.7881 82.9577 17.7881H92.0234C96.6134 17.7881 98.4832 19.5762 98.4832 22.8149C98.4832 25.6735 96.8851 26.6967 94.412 27.4001C97.1575 27.8505 99.1622 29.3311 99.1622 32.513C99.1622 36.1269 96.6106 37.7189 91.9966 37.7189Z"
            fill="#002776"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M166.85 8.29199C164.833 8.29199 162.889 8.60506 161.063 9.1806C168.747 7.52437 176.363 10.6694 179.107 17.1111C182.277 24.5562 177.738 33.5818 168.968 37.2695C161.101 40.5772 152.505 38.4636 148.528 32.62C150.854 40.4917 158.174 46.2417 166.85 46.2417C177.396 46.2417 185.947 37.7466 185.947 27.2665C185.947 16.7871 177.396 8.29199 166.85 8.29199Z"
            fill="#E60000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M168.968 37.2696C177.738 33.5819 182.278 24.5563 179.107 17.1112C176.363 10.6695 168.747 7.52446 161.063 9.18069C153.345 11.6168 147.753 18.792 147.753 27.2666C147.753 29.1265 148.027 30.9215 148.528 32.6201C152.505 38.4637 161.101 40.5772 168.968 37.2696Z"
            fill="#AF0000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.745 35.842V16.3582C171.745 15.9714 171.427 15.6562 171.037 15.6562H166.597C166.28 15.6562 166.102 15.7267 165.818 15.8319L160.089 18.7623C159.736 18.9038 159.595 19.1136 159.595 19.4656V22.6585C159.595 23.0337 159.901 23.3366 160.278 23.3366H163.944V38.6541C165.618 38.4231 167.309 37.9664 168.968 37.2692C169.948 36.8571 170.875 36.3779 171.745 35.842Z"
            fill="white"
        />
    </svg>
);

export const SB1DarkLogo = () => (
    <svg width="180" height="50" viewBox="0 0 180 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M170.212 25.865C170.212 35.6105 162.299 43.5107 152.537 43.5107C142.777 43.5107 134.863 35.6105 134.863 25.865C134.863 16.1189 142.777 8.21875 152.537 8.21875C162.299 8.21875 170.212 16.1189 170.212 25.865Z"
            fill="#F00000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M147.182 9.04665C140.04 11.3116 134.863 17.9842 134.863 25.8659C134.863 27.595 135.117 29.2642 135.581 30.8439C139.261 36.2783 147.217 38.2438 154.498 35.1677C162.615 31.7383 166.816 23.3449 163.881 16.4211C161.342 10.4305 154.293 7.50642 147.182 9.04665Z"
            fill="#B40000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.41 15.0664H152.301C152.008 15.0664 151.843 15.1319 151.58 15.2298L146.278 17.9549C145.951 18.0865 145.82 18.2816 145.82 18.6096V21.5782C145.82 21.9272 146.104 22.2095 146.453 22.2095H149.847V36.4409C151.396 36.2261 152.96 35.8141 154.496 35.1658C155.404 34.7818 156.26 34.333 157.065 33.834V15.7199C157.065 15.3595 156.772 15.0664 156.41 15.0664Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4158 35.8603C13.1502 35.8603 10.5628 35.1083 10.0853 34.9576C9.83379 34.8572 9.70837 34.6569 9.70837 34.4313V34.306L10.035 32.6501C10.0853 32.3997 10.261 32.3246 10.4622 32.3246C10.5125 32.3246 10.5628 32.3246 10.6379 32.3494C11.1657 32.4499 13.7277 33.1777 16.1395 33.1777C18.1991 33.1777 20.0327 32.5751 20.0327 30.4437C20.0327 28.2862 18.1488 28.0612 15.7122 27.7097C12.9994 27.3079 9.50781 26.5813 9.50781 22.3172C9.50781 17.9279 12.7733 16.7742 16.6418 16.7742C19.5807 16.7742 22.0179 17.4766 22.5699 17.6272C22.8717 17.7023 23.0226 17.9031 23.0226 18.1536V18.2794L22.6705 19.9093C22.6457 20.0848 22.4699 20.2602 22.2688 20.2602C22.2439 20.2602 22.2185 20.2354 22.193 20.2354C20.9878 19.9843 19.1287 19.4828 16.9181 19.4828C15.059 19.4828 13.1 19.8343 13.1 21.9409C13.1 24.0228 15.2099 24.3234 17.4453 24.6495C20.3848 25.1009 23.7255 25.5274 23.7255 30.1926C23.7255 34.5819 20.1588 35.8603 16.4158 35.8603Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.3348 35.5624C47.4555 35.7626 46.2497 35.9127 44.8432 35.9127C42.1297 35.9127 38.7891 35.6374 38.7891 31.6492C38.7891 28.4136 40.8239 28.0634 42.8332 27.7366L47.3053 27.0094V26.2066C47.3053 25.5041 47.2295 25.0026 46.6011 24.7273C46.1994 24.5513 45.5964 24.4763 44.6923 24.4763C42.9841 24.4763 41.125 24.9778 40.3718 25.1787C40.2961 25.1787 40.2464 25.2035 40.1955 25.2035C40.0701 25.2035 39.9446 25.1532 39.9192 24.9778L39.5677 23.4229V23.3225C39.5677 23.0968 39.7434 22.9964 39.8695 22.9462C40.4724 22.7205 42.7829 22.0938 45.3456 22.0938C47.1289 22.0938 48.3348 22.3442 49.1389 22.7962C50.4199 23.498 50.6714 24.6771 50.6714 26.0311V32.9536C50.6714 34.8345 50.043 35.1607 48.3348 35.5624ZM47.3068 29.1914L44.1916 29.693C43.1614 29.8436 42.207 29.9936 42.207 31.6235C42.207 33.5807 43.7892 33.7307 44.9957 33.7307C45.9749 33.7307 46.779 33.5553 47.3068 33.3792V29.1914Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.6316 24.675C58.0997 24.675 56.7933 24.8257 56.0897 25.0005V35.2088C56.0897 35.4344 55.9389 35.5851 55.7128 35.5851H53.1508C53 35.5851 52.7236 35.4344 52.7236 35.2088V24.3737C52.7236 23.3954 53.3011 23.0948 54.507 22.7439C55.6122 22.3923 57.3962 22.1419 58.8275 22.1165H59.2547C59.6068 22.1165 59.6068 22.1165 59.7825 22.1915C59.8831 22.2417 60.0085 22.3421 60.0085 22.5932V24.2231C60.0085 24.4742 59.983 24.675 59.6316 24.675Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.779 29.9652C72.779 30.2404 72.6282 30.5418 72.2512 30.5418H63.8363C63.8363 31.6961 64.1629 32.3229 64.8409 32.8492C65.4942 33.3756 66.4486 33.6013 67.8053 33.6013C69.3626 33.6013 70.543 33.2256 71.3217 32.9993C71.3726 32.9745 71.3975 32.9745 71.4478 32.9745C71.5986 32.9745 71.7241 33.0749 71.7992 33.326L72.1258 34.605C72.1258 34.6298 72.1513 34.6806 72.1513 34.7302C72.1513 34.9311 72.0252 35.0315 71.8495 35.1065C70.9957 35.4581 69.2875 35.9087 67.303 35.9087C62.3795 35.9087 60.4453 33.9776 60.4453 29.0625C60.4453 24.3471 61.7511 22.0898 66.6497 22.0898C69.1869 22.0898 70.7442 22.742 71.6489 23.946C72.5027 25.0743 72.779 26.6794 72.779 28.7606V29.9652ZM69.2383 26.2292C68.9117 24.8498 68.1578 24.3984 66.6763 24.3984C64.9929 24.3984 64.2894 24.9 64.0131 26.1535C63.8373 26.8559 63.8373 27.5838 63.8125 28.4108L69.4395 28.386C69.4395 27.5838 69.414 26.9062 69.2383 26.2292Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101.358 35.5624C100.479 35.7626 99.2731 35.9127 97.8667 35.9127C95.1532 35.9127 91.8125 35.6374 91.8125 31.6492C91.8125 28.4136 93.8473 28.0634 95.8573 27.7366L100.329 27.0094V26.2066C100.329 25.5041 100.253 25.0026 99.6252 24.7273C99.2234 24.5513 98.6205 24.4763 97.7164 24.4763C96.0076 24.4763 94.1485 24.9778 93.3953 25.1787C93.3195 25.1787 93.2692 25.2035 93.2196 25.2035C93.0941 25.2035 92.9687 25.1532 92.9432 24.9778L92.5918 23.4229V23.3225C92.5918 23.0968 92.7669 22.9964 92.8923 22.9462C93.4959 22.7205 95.807 22.0938 98.369 22.0938C100.152 22.0938 101.358 22.3442 102.162 22.7962C103.444 23.498 103.695 24.6771 103.695 26.0311V32.9536C103.695 34.8345 103.066 35.1607 101.358 35.5624ZM100.33 29.1914L97.2156 29.693C96.1849 29.8436 95.2305 29.9936 95.2305 31.6235C95.2305 33.5807 96.8133 33.7307 98.0185 33.7307C98.9983 33.7307 99.8025 33.5553 100.33 33.3792V29.1914Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M117.125 35.5872H114.537C114.311 35.5872 114.136 35.4365 114.136 35.2109V26.3572C114.136 24.9028 113.583 24.4012 112.252 24.4012C110.745 24.4012 109.112 24.8278 109.112 24.8278V35.2109C109.112 35.4365 108.961 35.5872 108.735 35.5872H106.173C105.921 35.5872 105.746 35.4365 105.746 35.2109V24.4012C105.746 23.4477 106.223 23.1216 107.63 22.746C108.911 22.3944 111.021 22.0938 112.629 22.0938C115.769 22.0938 117.502 22.896 117.502 25.9059V35.2109C117.502 35.4365 117.351 35.5872 117.125 35.5872Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131.694 35.3574C131.694 35.4585 131.594 35.5837 131.443 35.5837H128.077C127.951 35.5837 127.826 35.5837 127.7 35.4331L123.806 30.1404H122.952V35.2074C122.952 35.4331 122.827 35.5837 122.576 35.5837H119.988C119.763 35.5837 119.586 35.4331 119.586 35.2074V17.6755C119.586 17.3749 119.711 17.2242 120.014 17.1492L122.475 16.7227H122.576C122.802 16.7227 122.952 16.8733 122.952 17.1492V27.8832H123.806L127.776 22.5918C127.876 22.4412 127.977 22.4164 128.102 22.4164L131.167 22.4914C131.343 22.4914 131.418 22.6166 131.418 22.7171C131.418 22.7673 131.393 22.7927 131.368 22.8423L126.62 28.8119L131.645 35.2322C131.669 35.2576 131.694 35.3078 131.694 35.3574Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.2497 22.0938C30.567 22.0938 28.6328 22.3696 27.1251 22.896C25.995 23.2475 25.543 23.6232 25.543 24.9276V40.4526C25.543 40.7037 25.7397 40.9084 26.102 40.8429C26.4636 40.7781 28.4819 40.4056 28.4819 40.4056C28.7837 40.3554 28.9091 40.2054 28.9091 39.904V35.5312C29.8004 35.748 30.9248 35.9126 32.1746 35.9126C36.8472 35.9126 37.7258 32.9784 37.7258 29.0156C37.7258 25.028 37.098 22.0938 32.2497 22.0938ZM31.2964 33.6068C29.9403 33.6068 28.9102 33.3068 28.9102 33.3068V24.8542C28.9102 24.8542 30.3917 24.4531 31.5473 24.4531C33.4567 24.4531 34.2856 24.879 34.2856 28.9429C34.2856 32.7544 33.5821 33.6068 31.2964 33.6068Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.8192 35.585H75.4546C75.2279 35.585 75.0273 35.4089 75.0273 35.1839V17.4512C75.0273 17.2255 75.2279 17.05 75.4546 17.05H83.8447C88.092 17.05 89.8231 18.713 89.8231 21.7248C89.8231 24.3832 88.3441 25.3348 86.0552 25.9895C88.5962 26.4078 90.4509 27.7847 90.4509 30.7444C90.4509 34.1052 88.0894 35.585 83.8192 35.585ZM83.4928 19.6321H78.6445V24.9991L83.3171 24.7988C85.2265 24.7238 86.2057 24.0462 86.2057 22.1404C86.2057 20.3847 85.3519 19.6321 83.4928 19.6321ZM83.3674 27.4344L78.6445 27.2844V33.0023H83.3674C85.9046 33.0023 86.7335 31.8994 86.7335 30.3693C86.7335 28.3129 86.0555 27.5101 83.3674 27.4344Z"
            fill="white"
        />
    </svg>
);

export const SB1Logo = () => {
    const [isDarkMode, setIsDarkMode] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        setIsDarkMode(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches);
    }, []);

    return <>{isDarkMode !== undefined && (isDarkMode ? <SB1DarkLogo /> : <SB1LightLogo />)}</>;
};
